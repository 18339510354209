@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300&display=swap");
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  text-decoration: none;
  font-family: "Oswald", sans-serif;
  letter-spacing: 0.03rem;
}

a {
  cursor: pointer;
}
body {
  height: 100vh;
}
.App {
  display: grid;

}


.nav-bar {
  position:fixed;
  display: flex;
  justify-content: space-between;
  transition:0.5s;
  width:100vw;
  z-index: 99;
}
.scrolled {
  background-color: #F3B61F;

} 
.name-header {
  padding-left: 10px;
  color: black;
  font-size: 60px;
  font-weight: bold;
}
.dropdown-button {
  display: none;
}
.link-spread {
  display: flex;
  align-items: center;
}
.link-spread-container {
  display: flex;
}
.link {
  color: rgb(0, 0, 0);
  margin-right: 10vw;
  font-size: 30px;
  margin-bottom: 10px;
  cursor: pointer;
  text-decoration: underline 0.15em rgb(255, 255, 255, 0.4);
  text-underline-offset: 0.35em;
}
.link.is-active {
  text-decoration-color: white;
  text-underline-offset: 0.15em;
}

.link:hover,
.link:focus {
  transition: text-decoration-color 1s;
  text-decoration-color: rgb(0, 0, 0);
  text-underline-offset: 0.15em;
}



/* main */
main {
  overflow: hidden;
  grid-area: "main";
  display: flex;
  flex-direction: column;
  justify-content: normal;
  align-items: center;
  padding: 0px;
  margin: 0px;
  border:0px;
  margin-top: 15vh;;
}
.pages {
  height:100%;
  width:80%;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  margin-left: 10vw;
  margin-right: 10vw;
  margin-bottom: 30px;
  max-width: 900px;

}
.page-item {
  border-radius: 2px;
  margin: 20px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  box-shadow: 0px  8px 8px rgba(0, 0, 0, 0.224);
  max-width: 900px;
  border: 1px solid grey;
  opacity:0;
  transition:1.5s;
  float:right;
  transform: translateX(110%);
}

.active {
  opacity:1;
   transition: transform 1s;
  -webkit-transition: transform 1s;
  transform: translateX(0%);
  float:initial;
}

.page-item.page-body {
  min-height: 250px;
}

.subtitle.about{
  scroll-margin-top: 30vh;
}
.subtitle {
  font-size: 30px;
  text-align: center;
  font-weight: bold;
  scroll-margin-top: 15vh;

}
.page-text {
  font-size: 20px;
  margin: 0.5em 1em;
}
.view-count {
  font-size: 30px;
  text-align: center;
  font-weight: bold;
  background-color: rgba(128, 128, 128, 0.408);
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 20px;
  margin-bottom: 10px;
}

.self-image {
  position: relative;
  float: right;
  height: 200px;
  width: 200px;
  margin: 15px;
  border-radius: 50%;
  max-height: auto;
}

.page {
  height:100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.external-link:hover {
  color: blue;
}
.grid {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: minmax(175px, 1fr);
}

.contact-text {
  font-size: 30px;
  color: black;
}

.flip-card {
  border-radius: 2px;
  margin: 10px;
  height: 175px;
  perspective: 1000px;
}

.flip-card-inner {
  padding: auto, 10px;

  display: flex;
  flex-direction: vertical;
  align-items: center;
  justify-content: center;
  background-color: #F3B61F;
  position: relative;
  width: 100%;
  height: 100%;

  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid grey;

}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  text-align: center;
  position: absolute;
}

.flip-card-back {
  height: 100%;
  width: 100%;
  background-color: white;
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.flip-card-link {
  color: black;
}
.flip-card-link.hover:hover {
  color: blue;
}

.subheader {
  padding: 10px;
  height:20vh;
  display:none;
  font-size: 30px;
}

@media only screen and (max-width: 900px) {

  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-auto-rows: minmax(175px, 1fr);
  }
  .pages {
    width:auto;

  }
  iframe {
    max-width: 80vw;
  }

  .link-spread-container{
    visibility: hidden;
  }

}
@media only screen and (max-width: 750px) {
  body{
    background-color: #F3B61F;

  }
  .subheader { 
    display: block;
  }
  .flip-card {
    height: 100px;
  }
  .grid{
    grid-auto-rows: 100px;;
  }
  
  .name-header{
    font-size: min(25vh, 27vw);
    background-color: #F3B61F;

  }

  html{ 
    scroll-snap-type: y mandatory;
  max-height: 100vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
  }

  .nav-bar {
    position:relative;
    height:100vh;
    scroll-snap-align: start;
    flex-direction: column;
    align-items: flex-start;
    background-color: #F3B61F;
  }
  .page-item{
    opacity:1;
    transition: transform 1s;
   -webkit-transition: transform 1s;
   transform: translateX(0%);
   float:initial;
  }

main{
  scroll-snap-align: start;
}
  .link-spread-container {
    display: none;
  }

  body {
    display: block;
  }

  .pages {
    width: 100%;
    height: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
  }

  .intro {
    display: flex;
    flex-direction: column;
  }
  
  .img-wrap {
    overflow: hidden;
    display: flex;
    justify-content: center;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-auto-rows: minmax(100px, 1fr);
  }


@media only screen and (max-width: 500px) {
  .pages {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
  }

  .page-item {
    margin: 10px;
  }

  .grid {
    display: grid;
    justify-content: center;
    grid-template-columns: 80vw;
    grid-auto-rows: minmax(80px, 1fr);
  }
  .flip-card{
    height:80%
  }
  .flip-card-link {
    font-size: medium;
  }
}}

/* game */
.main-window {
  height: 60vh;
  width: min(60vw, 800px);
  border: solid black 2px;
  border-radius: 5px;
}

.window-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#shapeCanvas {
  width: 100%;
  height: 100%;
  position: relative;
}
